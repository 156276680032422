/**
 * actions.ts
 * List of Redux action types
 */

export enum LoginActionTypeEnum {
  SET_USER = 'login/set_user',
  SET_LOGIN = 'login/set_login',
  LOGOUT = 'login/logout',
  LOGIN_STATUS = 'login_status',
}

export enum SnackBarActionTypeEnum {
  UPDATE = 'SNACK_BAR_UPDATE',
  RESET = 'SNACK_BAR_RESET',
}

export enum AsyncTaskTypeEnum {
  ASYNC_TASK_START = 'ASYNC_TASK_START',
  ASYNC_TASK_STOP = 'ASYNC_TASK_STOP',
}

export enum PurchaseOrdersActionTypeEnum {
  PAGINATION_PURCHASE_ORDERS_SUCCESS = 'PAGINATION_PURCHASE_ORDERS_SUCCESS',
  READ_LIST_TO_TAG_PURCHASE_ORDERS_REQUEST = 'READ_LIST_TO_TAG_PURCHASE_ORDERS_REQUEST',
  READ_LIST_TO_TAG_PURCHASE_ORDERS_SUCCESS = 'READ_LIST_TO_TAG_PURCHASE_ORDERS_SUCCESS',
  READ_LIST_TAGGED_PURCHASE_ORDERS_REQUEST = 'READ_LIST_TAGGED_PURCHASE_ORDERS_REQUEST',
  READ_LIST_TAGGED_PURCHASE_ORDERS_SUCCESS = 'READ_LIST_TAGGED_PURCHASE_ORDERS_SUCCESS',
  GET_TAGGED_PURCHASE_ORDERS_SUCCESS = 'GET_TAGGED_PURCHASE_ORDERS_SUCCESS',
  GET_TAGGED_LINE_ITEM_COUNT_SUCCESS = 'GET_TAGGED_LINE_ITEM_COUNT_SUCCESS',
  GET_PARTIALLY_TAGGED_PURCHASE_ORDERS_SUCCESS = 'GET_PARTIALLY_TAGGED_PURCHASE_ORDERS_SUCCESS',
  GET_PENDING_CHECKIN_PURCHASE_ORDERS_SUCCESS = 'GET_PENDING_CHECKIN_PURCHASE_ORDERS_SUCCESS',
  GET_COMPLETELY_STORED_PURCHASE_ORDERS_SUCCESS = 'GET_COMPLETELY_STORED_PURCHASE_ORDERS_SUCCESS',
  GET_PARTIALLY_STORED_PURCHASE_ORDERS_SUCCESS = 'GET_PARTIALLY_STORED_PURCHASE_ORDERS_SUCCESS',
  GET_SEARCH_PURCHASE_ORDERS_SUCCESS = 'GET_SEARCH_PURCHASE_ORDERS_SUCCESS',
  LOAD_MORE_PURCHASE_ORDERS_ACTION = 'LOAD_MORE_PURCHASE_ORDERS_ACTION',
  READ_LIST_DELIVERED_PURCHASE_ORDERS_REQUEST = 'READ_LIST_DELIVERED_PURCHASE_ORDERS_REQUEST',
  READ_LIST_DELIVERED_PURCHASE_ORDERS_SUCCESS = 'READ_LIST_DELIVERED_PURCHASE_ORDERS_SUCCESS',
}

export enum SearchPurchaseOrderTypeEnum {
  READ_LIST_PURCHASE_ORDERS_SUCCESS = 'READ_LIST_PURCHASE_ORDERS_SUCCESS',
}

export enum NotificationActionTypeEnum {
  READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS',
  MASK_AS_READ_NOTIFICATION_SUCCESS = 'MASK_AS_READ_NOTIFICATION_SUCCESS',
}

export enum VersionActionTypeEnum {
  READ_VERSION_SUCCESS = 'READ_VERSION_SUCCESS',
}
